<template>
  <CContainer class="text-black">
    <div class="card border-greyborder" style="padding: 15px">
      <div class="d-flex">
        <div class="c-avatar">
          <img
            alt=""
            :src="logo"
            class="img-fluid"
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/869/869636.png';"
          />
        </div>
        <div style="padding-left: 10px">
          <h3 class="font-weight-bold">
            {{ shopName }}
          </h3>
          <router-link :to="`menu/${shopLineObjectId}/getshop`">
            <small class="text-description">See details</small>
          </router-link>
        </div>
      </div>

      <hr />
      <a data-toggle="modal" data-target="#modalPBottom">
        <div class="d-flex justify-content-between">
          <h6 class="mb-1 font-weight-bold text-grey">
            <em class="fas fa-store"></em> {{ branchName }}
          </h6>
          <small class="text-grey">เลือกสาขา >></small>
        </div>
      </a>
    </div>

    <div class="card">
      <div class="d-flex justify-content-between">
        <h6 class="mb-1 font-weight-bold">กลุ่มสินค้า</h6>
        <a data-toggle="modal" data-target="#categoryModal">
          <small class="text-grey">ดูเพิ่มเติม >></small>
        </a>
      </div>

      <div
        class="d-flex flex-row"
        style="overflow-x: auto"
        v-if="categories.length > 0"
      >
        <table aria-describedby="">
          <tr>
            <td
              style="padding: 2px"
              v-for="category in categories"
              :key="category.objectId"
              @click="goToByCategoryLists(category.objectId, category.name)"
            >
              <div
                class="circle shadow-menu"
                :style="{
                  'background-color': getCategoryColor(category.indexColor),
                }"
              >
                <h3>{{ catIcon(category.name) }}</h3>
              </div>
              <h6 class="text-center" style="font-size: 10px">
                {{ category.name }}
              </h6>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <grid-menu
      v-bind:cartTotal="cartTotal"
      @update-cartTotal="updateCartTotal"
    ></grid-menu>
    <br />

    <!-- Modal -->
    <div class="modal fade modal-p-bottom" id="modalPBottom">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-3">
            <p class="text-center">
              <strong>เลือกสาขา</strong>
            </p>
            <ul class="list-group">
              <li
                @click="shopHandle(shop.objectId)"
                class="list-group-item"
                v-for="shop in shopLineOA"
                :key="shop.objectId"
                :value="shop.objectId"
              >
                <em class="fas fa-store"></em> {{ shop.branchName }}
                <span
                  v-if="shopLineObjectId === shop.objectId"
                  style="float: right"
                  class="text-success"
                >
                  <em class="fas fa-check"></em>
                </span>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success btn-block"
              data-dismiss="modal"
              @click="getProduct()"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-p-bottom" id="categoryModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-3">
            <category-lists
              v-bind:shopLineObjectId="shopLineObjectId"
            ></category-lists>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed-bottom mb-6">
      <router-link to="/menu/mycart" class="text-black">
        <div class="notification-badges">
          <div class="cartbtn">
            <p :data-badge="cartTotal" v-if="cartTotal > 0">
              <CIcon name="cil-cart" size="xl"></CIcon>
            </p>
            <p v-else>
              <CIcon name="cil-cart" size="xl"></CIcon>
            </p>
          </div>
        </div>
      </router-link>
    </footer>
    <!-- <footer class="fixed-bottom" style="background-color:#ffff">
      <ul class="nav nav-fill">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">
            <CIcon name="cil-fastfood"></CIcon>
            <br />
            <small>
              รายการสินค้า
            </small>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            <CIcon name="cil-spreadsheet"></CIcon>
            <br />
            <small>
              คำสั่งซื้อของฉัน
            </small>
          </a>
        </li>
      </ul>
    </footer> -->
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import util from '@/util/util'
import CategoryLists from './CategoryLists'
import GridMenu from './GridMenu'
import '@/util/menu.css'

export default {
  components: {
    CategoryLists,
    GridMenu,
  },
  data() {
    return {
      products: [],
      categories: [],
      branchName: '',
      totally: 0,
      cartLists: [],
      cartTotal: 0,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopLineObjectId: {
      get() {
        return this.$store.getters.shopLineObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShopLineOA', newValue)
      },
    },
    shopName() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
  },
  created() {
    this.getProduct()
    this.getCategory()
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.getCartTotal()
  },
  methods: {
    ...util,
    updateCartTotal(total) {
      this.cartTotal = total
    },
    getCartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)

      this.cartTotal = total
    },
    catIcon(catName) {
      return catName.charAt(0).toUpperCase()
    },
    getCategoryColor(indexColor) {
      return util.generateColor(indexColor)
    },
    shopHandle(objectId) {
      this.shopLineObjectId = objectId
      this.cartTotal = 0
    },
    goToDetail(objectId) {
      this.$router.push('/menu/' + objectId + '/detail')
    },
    goToByCategoryLists(objectId) {
      this.$router.push('/menu/' + objectId + '/category')
    },
    getProduct(page = 1) {
      const uid = this.uid

      let params = {
        shopObjectId: this.shopLineObjectId,
        page: page,
        limit: 100,
      }

      pos
        .get('/api/v1.0/' + uid + '/Shop/getproduct/data', { params })
        .then((res) => {
          this.products = res.data.data

          this.branchName = this.products[0].shop.branchName || ''
          this.totally = this.products.length
        })
    },
    getCategory() {
      const uid = this.uid
      let shopObjectId = this.shopLineObjectId

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/menu/' + shopObjectId)
        .then((res) => {
          this.categories = res.data.data
        })
    },
  },
}
</script>
